import React, { Component } from "react"
import "./dl.scss"
import Link from "gatsby-link"

class MobileLinkRedirect extends Component {
  constructor(props) {
    super(props)
    if (typeof window !== `undefined`) {
      this.redirect(window.navigator.userAgent)
    }
  }

  render() {
    return (
      <div className="redirect-message-main">
        <div className="redirect-message">Redirecting...</div>
        <div className="button redirect-button">
          <Link to="/">
            <button className="button-a">
              <span>Back to Home</span>
            </button>
          </Link>
        </div>
      </div>
    )
  }

  redirect = userAgent => {
    if (userAgent.match(/Android/i))
      return (window.location =
        "https://play.google.com/store/apps/details?id=com.nearby.share")
    else if (userAgent.match(/iPhone|iPad|iPod/i))
      return (window.location =
        "https://apps.apple.com/us/app/elapp-nearby-sharing/id1543464747")
    else if (userAgent.indexOf("Win") != -1)
      return (window.location =
        "https://cdn.criptext.com/elapp/windows/elapp-latest.exe")
    else if (userAgent.indexOf("Mac") != -1)
      return (window.location =
        "https://cdn.criptext.com/elapp/mac/elapp-latest.dmg")
    else if (userAgent.indexOf("Linux") != -1)
      return (window.location =
        "https://cdn.criptext.com/elapp/linux/elapp-latest.AppImage")
    return (window.location =
      "https://cdn.criptext.com/elapp/linux/elapp-latest.AppImage")
  }
}

export default MobileLinkRedirect
